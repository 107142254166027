<template>
  <div class="switch">
    <!-- 参数 -->
    <Head :name="'智能开关'" :add="'/pc/ProSwitch'" :adds="'/pc/ProSwitchParam'" :color="true" />
    <!-- banner -->
    <div class="bannerBox">
      <div class="banner">
        <div class="bName">一身才华，一触即发</div>
        <div class="bTit">智能开关</div>
        <div class="line"></div>
        <div class="bText">
          <img src="../../../assets/product/si1.png" class="bImg" />
          <div class="bTexts">四个按钮，随心定制</div>
        </div>
        <div class="bText">
          <img src="../../../assets/product/si2.png" class="bImg" />
          <div class="bTexts">不用动手，语音控制</div>
        </div>
        <div class="bText">
          <img src="../../../assets/product/si3.png" class="bImg" />
          <div class="bTexts">智能联动，一按即达</div>
        </div>
        <div class="bText">
          <img src="../../../assets/product/si4.png" class="bImg" />
          <div class="bTexts">过温过载保护，守护安全</div>
        </div>
      </div>
    </div>
    <div class="sec">
      <div class="secTit">无框设计，出色质感，百搭配色</div>
      <div class="secTxt">简洁的无框设计，优雅奢华的香槟金，简约大气的黑色，深邃沉稳的绅士灰。</div>
      <div class="secTxt secTxts">轻松搭配各种装修风格，提升品味，打造奢华。</div>
    </div>
    <div class="sec1"></div>
    <div class="secNew">
      <div class="secTit">无线互联，摆脱束缚</div>
      <div class="secTxt">全新的高可靠自研无线组网方案，高度适配硬件，并对酒店物联网特别优化。</div>
      <div class="secTxt secTxts">搭配超级主机，在解决布线繁琐的同时，稳定性也丝毫不减。</div>
      <div class="img"></div>
    </div>

    <div class="sec">
      <div class="secTit">无限场景，自由定义</div>
      <div class="secTxt">搭配其他智能设备和超级主机，可以根据您的需求自由定义丰富的场景控制。</div>
      <div class="secTxt">开创性实现了一个开关既可以实现控制基础照明，也可以控制场景的多重功能，轻松实现更多场景。</div>
    </div>
    <div class="sec2">
      <div class="waveBox">
        <div class="wave1"></div>
        <div class="wave2"></div>
        <div class="wave3"></div>
        <div class="wave4"></div>
      </div>
    </div>
    <div class="sec3">
      <div class="waveBox">
        <div class="wave1"></div>
        <div class="wave2"></div>
        <div class="wave3"></div>
        <div class="wave4"></div>
      </div>
    </div>
    <!-- 魅力 -->
    <div class="sec4">
      <div class="sec4Box">
        <div class="secTit">时时刻刻，感受自动化的新魅力</div>
        <div class="secUl">
          <div class="secLi"></div>
          <div class="secLi">
            <div class="liTit">开门亮灯 贴心感知</div>
            <div class="liText">刷卡开门，灯光即自动亮起，电子设备、家电便开始工作。</div>
            <div class="liText">打造用户入住新体验。</div>
          </div>
          <div class="secLi">
            <div class="liTit">离店从容 尽享安心</div>
            <div class="liText">准备出门，一键开启离店模式，灯光、窗帘自动关闭。</div>
            <div class="liText">出门关灯，尽享轻松。</div>
          </div>
          <div class="secLi"></div>
          <div class="secLi"></div>
          <div class="secLi">
            <div class="liTit">一键场景 一键关灯</div>
            <div class="liText">躺在床上，伸手按一下摆在床头的开关，灯光即可全部关闭。</div>
            <div class="liText">房间灯光，轻松掌握。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.bannerBox {
  width: 100%;
  height: 8rem;
  background-image: url("../../../assets/product/switchBanner.png");
  background-size: 100% 100%;
  .banner {
    width: 12rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #000;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    .bName {
      font-size: 0.3rem;
      line-height: 0.3rem;
      margin-top: 1.41rem;
    }
    .bTit {
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #333;
      margin-top: 0.19rem;
    }
    .line {
      width: 0.4rem;
      height: 0.04rem;
      background: #ff9000;
      margin-top: 0.32rem;
      margin-bottom: 1.11rem;
    }
    .bText {
      display: flex;
      align-items: center;
      margin-bottom: 0.26rem;
      &:last-of-type {
        margin-bottom: 0px;
      }
      .bImg {
        width: 0.6rem;
        height: 0.6rem;
        margin-right: 0.16rem;
        margin-left: -0.1rem;
      }
      .bTexts {
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
        color: #000;
      }
    }
  }
}
.sec {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 0.24rem;
  line-height: 0.24rem;
  font-weight: 400;
  color: #333333;
  padding-top: 1.2rem;
  padding-bottom: 0.8rem;
  .secTit {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
  }
  .secTxt {
    margin-top: 0.37rem;
    &:last-of-type {
      margin-top: 0.16rem;
    }
  }
}
.secNew {
  width: 100%;
  padding: 0.8rem 0px;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.24rem;
  line-height: 0.24rem;
  font-weight: 400;
  color: #333333;
  .secTit {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
  }
  .secTxt {
    margin-top: 0.4rem;
  }
  .secTxts {
    margin-top: 0.16rem;
  }
  .img {
    width: 12rem;
    height: 6rem;
    margin: 0 auto;
    margin-top: 0.61rem;
    background-image: url("../../../assets/product/switchGateway.png");
    background-size: 100% 100%;
  }
}
.sec1 {
  width: 100%;
  height: 6.4rem;
  background-image: url("../../../assets/product/switch1.png");
  background-size: 100% 100%;
  margin-bottom: 1.2rem;
}
.sec2 {
  width: 100%;
  height: 8rem;
  background-image: url("../../../assets/product/switch2.png");
  background-size: 100% 100%;
  position: relative;
  .waveBox {
    top: 3.1rem;
    right: 2.52rem;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.07);
    animation: w 1.5s ease 0.2s infinite;
    position: absolute;
    .wave1 {
      position: absolute;
      margin-left: 0.2rem;
      margin-top: 0.2rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.1);
      z-index: 2;
    }
    .wave2 {
      position: absolute;
      margin-left: 0.45rem;
      margin-top: 0.45rem;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.2);
      z-index: 3;
    }
    .wave3 {
      position: absolute;
      margin-left: 0.6rem;
      margin-top: 0.6rem;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.4);
      z-index: 4;
    }
    .wave4 {
      position: absolute;
      margin-left: 0.75rem;
      margin-top: 0.75rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 1);
      z-index: 5;
    }
    @keyframes w {
      0% {
        -moz-transform: scale(0, 0);
        -webkit-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        opacity: 0;
      }
      100% {
        -moz-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        opacity: 1;
      }
    }
    @-webkit-keyframes w {
      0% {
        -moz-transform: scale(0, 0);
        -webkit-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        opacity: 0;
      }
      100% {
        -moz-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        opacity: 1;
      }
    }
  }
}
.sec3 {
  width: 100%;
  height: 8rem;
  background-image: url("../../../assets/product/switch3.png");
  background-size: 100% 100%;
  position: relative;
  .waveBox {
    top: 3.4rem;
    left: 2.48rem;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: rgba(255, 237, 170, 0.07);
    animation: w 1.5s ease 0.2s infinite;
    position: absolute;
    .wave1 {
      position: absolute;
      margin-left: 0.2rem;
      margin-top: 0.2rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: rgba(255, 237, 170, 0.1);
      z-index: 2;
    }
    .wave2 {
      position: absolute;
      margin-left: 0.45rem;
      margin-top: 0.45rem;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background-color: rgba(255, 237, 170, 0.2);
      z-index: 3;
    }
    .wave3 {
      position: absolute;
      margin-left: 0.6rem;
      margin-top: 0.6rem;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: rgba(255, 237, 170, 0.4);
      z-index: 4;
    }
    .wave4 {
      position: absolute;
      margin-left: 0.75rem;
      margin-top: 0.75rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      background-color: rgba(255, 237, 170, 1);
      z-index: 5;
    }
    @keyframes w {
      0% {
        -moz-transform: scale(0, 0);
        -webkit-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        opacity: 0;
      }
      100% {
        -moz-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        opacity: 1;
      }
    }
    @-webkit-keyframes w {
      0% {
        -moz-transform: scale(0, 0);
        -webkit-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        opacity: 0;
      }
      100% {
        -moz-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        opacity: 1;
      }
    }
  }
}
// 魅力
.sec4 {
  width: 100%;
  background-color: #fff;
  .sec4Box {
    // width: 1200px;
    width: 12rem;
    margin: 0 auto;
    // padding: 120px 0px;
    padding: 1.2rem 0px;
    // font-size: 36px;
    font-size: 0.36rem;
    line-height: 36px;
    font-weight: bold;
    color: #333;
    .secTit {
      text-align: center;
    }
    .secUl {
      display: flex;
      flex-wrap: wrap;
      // margin-top: 79px;
      margin-top: 0.79rem;
      .secLi {
        width: 50%;
        // height: 400px;
        height: 4rem;
        background: #f8f8f8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:first-of-type {
          background-image: url("../../../assets/product/switch4.png");
          background-size: 100% 100%;
        }
        &:nth-child(4) {
          background-image: url("../../../assets/product/switch5.png");
          background-size: 100% 100%;
        }
        &:nth-child(5) {
          background-image: url("../../../assets/product/switch6.png");
          background-size: 100% 100%;
        }
        .liText {
          margin-top: 0.4rem;
          font-size: 0.18rem;
          line-height: 18px;
          font-weight: 400;
          &:last-of-type {
            margin-top: 12px;
          }
        }
      }
    }
  }
}
</style>
